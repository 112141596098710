.css-1twr7re-JoyAccordionSummary-button.Mui-disabled{
    color: white !important;
}
.css-1twr7re-JoyAccordionSummary-button:not(.Mui-selected, [aria-selected="true"]):hover {
    border-radius: 12px;
}

@media print {
    .print-title {
      display: flex !important; /* Override hidden */
      justify-content: center;
      align-items: center;
    }
}