.dashboard-card {
  background-color: #f4f7fc;
  border-radius: 8px;
  border: 1px solid rgb(123, 111, 111);
  display: flex;
  flex-direction: column;
  width: 300px;
  height:7.5rem;
  overflow: hidden;

}

.card-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1rem; */
  margin-top: 0rem;

}

.card-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.1rem;
}

.help-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.card-body {
  display: flex;
  align-items: center;
  justify-content: start;

}

.card-count {
  font-size: 2rem;
  font-weight: bold;
  margin-right: 0.5rem;

}

.card-count-type {
  margin-top: 5px;
  font-size: 0.8rem;
  color: #6b7280; /* Tailwind Gray 500 */
}

.card-footer {
  display: flex;
  justify-content: start;
}

.increment-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #10b981; /* Tailwind Green 500 */
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem; /* Tailwind rounded-md */
  cursor: pointer;
  transition: background-color 0.2s;
}

.increment-button:hover {
  background-color: #059669; /* Tailwind Green 600 */
}

.increment-icon {
  margin-left: 0.5rem;
  width: 16px;
  height: 16px;
}

/* @media (max-width: 640px) {
  .dashboard-card {
    width: auto;
    margin: 10px;

  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .dashboard-card {
    width: 45%;
    margin: 10px;


  }
}

@media (min-width: 1008px) {
  .dashboard-card {
    width: 22.5%;
    margin: 10px;

  }
} */
@media (max-width: 640px) {
  .dashboard-card {

   width: 100%; /* Full width on small screens */
    /* margin: 10px; */

  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .dashboard-card {
    max-width: 100%; /* Adjust width for medium screens */
    /* margin: 10px; */
     /* Adjust margin for medium screens */

  }
}

/* @media (min-width: 1008px) {
  .dashboard-card {


  }
} */
