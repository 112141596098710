.add-domain-container {

  max-width: auto;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f4f7fc;
  border-radius: 8px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
}

.add-domain-title {
  font-size: 1.625rem;
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
}

.add-domain-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-domain-form {
  width: 100%;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.submit-btn {
  background-color: #369AFE !important;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  width: fit-content;

}

.submit-btn:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .add-domain-container {
    padding: 1rem;
  }

  .add-domain-form-container {
    width: 100%;
  }
}
