.accessibility-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  padding: 20px;
}

.accessibility-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  padding: 20px;
  text-align: center;
  width: calc(33.333% - 20px);
  box-sizing: border-box;
}

.accessibility-card .card-header {
  font-weight: bold;
  margin-bottom: 10px;
}

.card-status, .card-score, .card-risk {
  margin: 20px 0;
  font-size: 1.2em;
}

.not-compliant {
  font-weight: bold;
  color: #d9534f;
}

.high {
  font-weight: bold;
  color: #d9534f;
}

.low{
  font-weight: bold;
  color: green
}


.score-circle span {
  display: inline-block;
  font-size: 1.5em;
}
.accessibility-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
}

.search-input {
  padding: 10px;
  font-size: 1em;
  border: 2px solid #ccc;
  border-radius: 5px;
  flex-grow: 1;
  margin-right: 10px; /* Spacing between the input and the button */
}

.search-button {
  padding: 10px 30px;
  font-size: 1em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Add to Accessibility.css */
.accordion details {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 10px; /* Space between accordion items */
  width: 100%;
}

.accordion summary {
  font-weight: bold;
  cursor: pointer;
  list-style: none; /* Remove the default marker */
}

.accordion summary::-webkit-details-marker {
  display: none; /* Hide the default arrow icon in Chrome */
}

.fail-count {
  float: right; /* Position fail count to the right */
  background-color: #ec4545;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.8em;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .accordion details {
    width: auto;
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .search-bar-container {
    flex-direction: column;
    width: 90%;
  }

  .search-input {
    width: 100%;
    margin-bottom: 10px;
  }

  .search-button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .accessibility-container {
    flex-direction: column;
    align-items: center;
  }
  .accessibility-card {
    width: 100%;
  }
}


@keyframes slideIn {
  0% {
    width: 0;
    opacity: 0;
  }
  50% {
    width: 3rem; /* Arrow body length */
    opacity: 1;
  }
  100% {
    width: 3rem;
    opacity: 0;
  }
}

@keyframes drawIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.arrow-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 1rem; /* Distance from the button */
}

.arrow-animation {
  display: flex;
  align-items: center;
}

.arrow-body {
  background-color: #3490dc; /* Primary color */
  height: 2px; /* Line thickness */
  animation: slideIn 2s infinite;
}

.arrow-head {
  width: 0; 
  height: 0; 
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #3490dc; /* Primary color for the arrow head */
  animation: drawIn 2s infinite;
  animation-delay: 1s; /* Starts drawing the head after the body animation begins */
}
