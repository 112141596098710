.react-slideshow-container {
  justify-content: center;
  height: 100%;
}
.indicators {
  position: absolute;
  bottom: 130px;
  left: 0;
  right: 0;
  text-align: center;
}
.react-slideshow-container+ul.indicators .each-slideshow-indicator {
  background: #FFFFFF;
  mix-blend-mode: normal;
  opacity: 0.45;
  padding: 0;
}
.react-slideshow-container+ul.indicators .each-slideshow-indicator:before {
  background: #ffffff;
}

@media screen and (max-width: 740px) {
  .indicators {
    bottom: -15px;
  }
}
